<template>
  <el-container class="company">
    <el-main>
      <ul v-infinite-scroll="load"
          infinite-scroll-distance="30"
          :infinite-scroll-disabled="disabled"
      >
        <company-item v-for="(company,index) in lists" :company="company" :key="index" />
      </ul>
      <div class="load-tip">
        <p v-if="loading">加载中...</p>
        <p v-if="noMore">没有更多了</p>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import CompanyItem from "@/components/Article/CompanyItem";
import { listCompanyByAlbumId } from "@/apis/module";

export default {
  name: "CompanyListTemplate",
  components: { CompanyItem },
  data() {
    return {
      loading: false,
      menuItems: [],
      lists: [],
      noMore: false,
      page: 1,
      disabled: false
    };
  },
  props: {
    albumId: {
      type: Number
    }
  },
  methods: {
    load() {
      listCompanyByAlbumId(this.albumId, this.page).then(res => {
        if (!res.data || res.data.length <= 0) {
          this.noMore = true;
          this.disabled = true;
        } else {
          this.lists = this.lists.concat(res.data);
          this.page = this.page + 1;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.company {
  width: calc(853px - 27px);

  .header {
    padding: 0 16px;
    margin-top: 10px;
    margin-bottom: 16px;

    .label {
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
    }
  }

  .main {
    display: flex;
    flex-wrap: wrap;

  }

  .load-tip {
    text-align: center;
    font-size: 14px;
    color: #5e6d82;
    line-height: 20px;
    margin-top: 20px;
  }
}
</style>
