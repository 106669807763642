<template>
  <div class="company-item" v-if="company">
    <img :src="company.companyLogo" class="company-logo" />
    <div class="company-right-div">
      <div style="flex: 1">
        <div style="display: flex">
          <div class="company-title">{{ company.companyName }}</div>
          <div class="company-tags">
            <span v-for="(tag,index) in getCompanyTags()" class="tag" :key="index">
              {{ tag }}
            </span>
          </div>
        </div>
        <div style="display: flex;margin-top: 10px">
          <div class="company-desc">{{ company.companyDesc }}</div>
        </div>
      </div>
      <el-button class="company-submit-btn" @click="submit" v-if="company.inviteLink || company.bossLink">去投递
      </el-button>

    </div>

  </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";

export default {
  name: "CompanyItem",
  data() {
    return {};
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  props: {
    company: {
      type: Object
    }
  },
  methods: {
    ...mapActions(["UPDATE_LOGIN_VISIABLE", "UPDATE_VIP_VISIBLE", "UPDATE_WECHAT_SERVICE_VISIBLE"]),
    submit() {
      // if (this.userInfo?.isVip !== 1) {
      //   this.UPDATE_VIP_VISIBLE(true);
      // } else {
        let link = "";
        if (this.company.inviteLink) {
          link = this.company.inviteLink;
        } else {
          link = this.company.bossLink;
        }
        window.open(link, "_blank");
      // }
    },
    getCompanyTags() {
      let tags = [];
      if (this.company) {
        tags = this.company.businessTags ?? [];
        tags = tags.concat(this.company.ipoStatus, this.company.personTxt);
      }
      return tags;
    }
  }
};
</script>

<style lang="scss">
.company-item {
  display: flex;
  margin-bottom: 15px;
  padding-bottom: 20px;
  margin-top: 15px;
  background-color: #fff;

  .company-logo {
    width: 93px;
    height: 93px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 27px;
  }


  .company-right-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 70px - 10px);

    .company-title {
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
    }

    .company-tags {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      margin-left: 20px;
      flex: 1;

      .tag {
        white-space: nowrap;
        flex-direction: column;
        align-items: center;
        padding: 4px 10px;
        background: rgba(0, 82, 254, 0.1);
        border-radius: 8px;
        text-align: center;
        margin-right: 10px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0052FE;
        line-height: 32px;
      }
    }

    .company-desc {
      flex: 1;
      font-size: 19px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 32px;
    }

    .company-submit-btn {
      background: #0052FE;
      border-radius: 6px;
      height: 53px;
      padding: 13px 20px;
      margin-left: 40px;
      text-align: center;
      font-size: 19px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 27px;
    }
  }
}
</style>