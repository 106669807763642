<!-- 文章详情 -->
<template>
  <div class="resume-home">

    <div class="breadcrumb" @click="goBack">
      <el-icon class="el-icon-arrow-left" />
      返回
    </div>

    <img class="collect-status"
         :src="collectStatus === false ? 'https://res.offertalks.cn/miniapp/icon_uncollect.png':'https://res.offertalks.cn/miniapp/icon_collected.png'"
         @click="collect"/>


    <div class="main-item" @click="openResume(getFirstContent('firstResume').id)">
      <img class="img" :src="album.backgroundImage" />
      <div class="item-right" style="width: 100%">
        <div class="title" style="text-align: center;display: flex;align-items: center">
          <span style="margin-right: 20px;text-align: start;flex: 1">{{ album.title }}</span>
          <el-button class="vip-btn" style="margin-top: 0 !important;"
                     @click.stop="openResume(getFirstContent('firstResume').id)">Ta的简历
          </el-button>

        </div>
        <div class="resume-tags">
            <span v-for="(tag,index) in getTags()" class="tag" :key="index">
              {{ tag }}
            </span>
        </div>
        <img class="vip-icon" style="margin-top: 0px"
             v-if="album.price > 0"
             src="https://res.offertalks.cn/miniapp/icon-vip.png"
             @click.stop="vipBtn" />
      </div>
    </div>
    <p class="text" style="white-space: pre-line" @click="openResume(getFirstContent('firstResume').id)">
      {{ album.content }}
    </p>

    <el-container>
      <!-- TAB -->
      <el-tabs @tab-click="handleTabChange" v-model="tabSelected.type" style="margin-top: 30px" :stretch="true">
        <el-tab-pane v-for="(item,index) in tabList" :key="index" :label="item.label" :name="item.type"
        >

          <div v-if="tabSelected.type === 'delivery'">
<!--            <company-item v-for="(company,index) in tabList[tabSelected.index].data" :company="company" :key="index" />-->
            <company-list-template :album-id="album.id"/>
          </div>
          <div v-if="tabSelected.type === 'resume'">
            <resume-item v-for="(resume,index) in tabList[tabSelected.index].data" :resume="resume" :key="index"
                         :open-resume="openResume"
                         :album-price="album.price" />
          </div>
          <div v-if="tabSelected.type === 'interview'">
            <interview-item-template v-for="(data,index) in tabList[tabSelected.index].data"
                                     :item="data"
                                     :key="index"
                                     :is-free="album.price <= 0"
                                     :open-interview="openInterview"
            />
          </div>
          <div v-if="tabSelected.type === 'knowledge'">
            <knowledge-item-template v-for="(data,index) in tabList[tabSelected.index].data" :item="data" :key="index"
                                     :open-knowledge="openKnowledge"
                                     :is-free="album.price <= 0" />
          </div>
        </el-tab-pane>

      </el-tabs>
    </el-container>


    <div class="fixed-bottom-fight" v-if="getFirstContent('operationBtn').vip === true">
      <div style="display: flex;align-items: center">
        <!--        <span class="price-text1">￥99</span>-->
        <!--        <span class="price-text2">/永久</span>-->
        <img
          v-if="album.price > 0"
          class="vip-icon" src="https://res.offertalks.cn/miniapp/icon-vip.png" />
      </div>
      <el-button class="vip-btn" @click="download(vipBtn,'resume',album.id)">{{ userInfo && userInfo.isVip === 1 ? "立即下载" : "立即升级" }}</el-button>
    </div>

<!--    <el-dialog :visible.sync="openResumeDialog" width="80%" class="resume-detail-dialog">-->
<!--      -->
<!--    </el-dialog>-->

    <preview-item-dialog
      :open-item-detail-dialog="openItemDetailDialog"
      :item-detail="itemDetail"
      :item-type="itemType"
      :album="album"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { collectRequest, getAlbumModuleDetail, unCollectRequest } from "@/apis/module";
import CompanyListTemplate from "@/views/company/CompanyListTemplate";
import PreviewItemDialog from "@/components/PreviewItemDialog";
import ResumeItem from "@/components/Article/ResumeItem";
import InterviewItemTemplate from "@/components/Article/InterviewItemTemplate";
import KnowledgeItemTemplate from "@/components/Article/KnowledgeItemTpl";
import { download } from "@/apis/download";

export default {
  name: "ModuleDetail",
  data() {
    return {
      tabList: [],
      tabSelected: {
        index: 0,
        type: null
      },
      tabTypeMap: {
        "delivery": "Ta的投递",
        "resume": "同类简历",
        "knowledge": "Ta的项目",
        "interview": "Ta的面经"
      },
      albumId: 0,
      album: {
        backgroundImage: "",
        title: "",
        audioUrl: ""
      },
      audioUrl: "",
      title: "",
      scene: "",
      modules: null,
      collectStatus: false,
      relateAlbumList: [],
      openItemDetailDialog: false,
      itemDetail:{},
      itemType:null

    };
  },
  components: { KnowledgeItemTemplate, InterviewItemTemplate, ResumeItem, PreviewItemDialog, CompanyListTemplate,  },
  created() {
    this.albumId = this.$route.params.albumId;
    this.scene = this.$route.params.scene;
    this.getAlbumDetail();
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });
  },

  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    collect() {
      if (this.collectStatus) {
        unCollectRequest(this.albumId).then((res) => {
          if (res.data) {
            this.collectStatus = false;
          }
        });
      } else {
        collectRequest(this.albumId).then((res) => {
          if (res.data) {
            this.collectStatus = true;
          }
        });
      }
    },
    download(callbackFun, contentType, contentId) {
      if (this.userInfo?.isVip === 0) {
        //非会员 不允许下载
        this.UPDATE_VIP_VISIBLE(true);
        return;
      }
      download(contentType, contentId).then(res => {
        if (res.data) {
          callbackFun();
        } else {
          //会员下载失败
          this.$message.error("超过下载次数限制，请联系客服为你开通高级VIP权限");
          this.UPDATE_WECHAT_SERVICE_VISIBLE(true);
        }
      });
    },
    validAndShowLogin() {
      if (!this.userInfo) {
        this.UPDATE_LOGIN_VISIABLE(true);
        return true;
      }
      return false;
    },
    openInterview(item) {
      if (this.validAndShowLogin()) {
        return;
      }
      this.openItemDetailDialog = true
      this.itemType = 'interview'
      this.itemDetail = item
    },
    openKnowledge(item) {
      if (this.validAndShowLogin()) {
        return;
      }
      this.openItemDetailDialog = true
      this.itemType = 'knowledge'
      this.itemDetail = item
    },
    openResume(openResumeId) {
      if (this.validAndShowLogin()) {
        return;
      }
      //查看简历
      if (this.album.price > 0) {
        console.log("user", this.userInfo);
        //收费
        if (this.userInfo?.isVip === 1) {
          this.itemType = 'resume'
          this.openItemDetailDialog = true
          this.itemDetail.id = openResumeId;
          // this.pdfOptions.filename = this.getFirstContent('firstResume').title + ".pdf"
        } else {
          this.UPDATE_VIP_VISIBLE(true);
        }
      } else {
        //免费
        this.itemType = 'resume'
        this.openItemDetailDialog = true
        this.itemDetail.id = openResumeId;
        // this.pdfOptions.filename = this.getFirstContent('firstResume').title + ".pdf"
      }
    },
    vipBtn() {
      if (!this.userInfo) {
        this.UPDATE_LOGIN_VISIABLE(true);
        return;
      }
      if (this.userInfo?.isVip === 0) {
        //非会员
        this.UPDATE_VIP_VISIBLE(true);
      } else {
        this.itemType = 'resume'
        this.openItemDetailDialog = true
        // this.pdfOptions.filename = this.getFirstContent('firstResume').title + ".pdf"
        this.itemDetail.id = this.getFirstContent("firstResume").id;
      }
    },
    // downloadResume() {
    //   if (!this.userInfo) {
    //     this.UPDATE_LOGIN_VISIABLE(true);
    //     return;
    //   }
    //   console.log("Parent downloadResume");
    //   this.$refs.resumeDetailRef.downloadResume();
    // },
    handleTabChange(tab) {
      this.tabSelected = {
        index: tab.index,
        type: this.tabList[tab.index].type
      };
    },
    getTags() {
      return (this.getFirstContent("firstResume")?.jobTags ?? []).concat(this.getFirstContent("firstResume")?.businessTags);
    },
    getFirstContent(key) {
      if (this.modules) {
        for (let i = 0; i < this.modules.length; i++) {
          let data = this.modules[i];
          if (data.type === key) {
            return data.dataList[0];
          }
        }
      }
      return {};
    },
    _initTabList() {
      if (this.modules) {
        const typeArr = Object.keys(this.tabTypeMap);
        for (let i = 0; i < this.modules.length; i++) {
          let data = this.modules[i];

          if (typeArr.indexOf(data.type) > -1) {
            this.tabList.push({
              type: data.type,
              label: this.tabTypeMap[data.type],
              data: this.__initData(data.type, data.dataList)
            });
          }
        }
        this.tabSelected = {
          index: 0,
          type: this.tabList[0].type
        };
      }
    },
    __initData(type, dataList) {
      let res = [];
      if (type === "delivery") {
        // dataList.forEach(item => {
        //   Object.keys(item.companyMap).forEach(i => {
        //     res = res.concat(item.companyMap[i]);
        //   });
        // });
      } else {
        res = res.concat(dataList);
      }
      return res;
    },

    clickBtn(item) {
      if (item.type === "link") {
        window.open(item.url, "_blank");
      } else if (item.type === "shequn") {
        // this.$router.push({
        //   path: "/offertalks",
        // });
      } else if (item.type === "sijiao") {
        this.$router.push({
          path: "/offertalks"
        });
      }
    },
    getAlbumDetail() {
      getAlbumModuleDetail(this.albumId).then((res) => {
        this.album = res.data.album;
        this.modules = res.data.module?.contentList || [];
        this.collectStatus = this.album.collectStatus;
        res.data.module?.contentList.forEach((content) => {
          if (content.type === "album") {
            this.relateAlbumList = this.relateAlbumList.concat(
              content.dataList
            );
          }
        });
        this._initTabList();
      });
    },
    ...mapActions(["UPDATE_LOGIN_VISIABLE", "UPDATE_VIP_VISIBLE","UPDATE_WECHAT_SERVICE_VISIBLE"])
  }
};
</script>
<style lang="scss" scoped>
.resume-home {
  background-color: #fff;
  padding-bottom: 100px;

  .collect-status {
    position: fixed;
    font-size: 50px;
    text-align: center;
    line-height: 60px;
    border-radius: 50%;
    right: 100px;
    bottom: 300px;
    cursor: pointer;
  }

  .collect-status-on {
    color: #0052FE;
  }

  .collect-status-off {
    color: grey;
  }

  .breadcrumb {
    margin-bottom: 10px;
    cursor: pointer;
    position: absolute;
    left: 260px;
    height: 24px;
    font-size: 24px;
    margin-top: -30px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #333333;
    line-height: 32px;

    img {
      margin-right: 5px;
      width: 16px;
      height: 12px;
    }
  }

  .main-item {
    display: flex;
    margin-top: 40px;

    .img {
      width: 100px;
      height: 140px;
      margin-right: 27px;
      border-radius: 10px;
      object-fit: cover;
    }

    .item-right {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 29px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
      }

      .resume-tags {
        margin-top: 17px;
        display: flex;
        margin-bottom: 13px;
        flex-direction: row;
        overflow-x: auto;

        .tag {
          white-space: nowrap;
          flex-direction: column;
          align-items: center;
          padding: 4px 12px;
          background: rgba(0, 82, 254, 0.1);
          border-radius: 4px;
          font-size: 16px;
          text-align: center;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0052FE;
          line-height: 23px;
          margin-right: 12px;
        }
      }

    }
  }

  .text {
    font-size: 21px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
    margin-top: 24px;
  }



  .vip-icon {
    width: 185px;
    height: 43px;
    margin-top: 20px;
  }


}

.fixed-bottom-fight {
  position: fixed;
  bottom: 42px;
  z-index: 1000;
  width: calc(100% - 10% - 6px);
  display: flex;
  flex-direction: row;
  height: 50px;
  background-color: #fff;
  left: calc(12% + 6px);
  align-items: center;
  justify-content: end;
  padding-right: 75px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);


  .price-text1 {
    font-size: 35px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #26232A;
    line-height: 49px;
  }

  .price-text2 {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
  }


}


.flex {
  display: flex;
}


.el-header {
  display: flex;
  align-items: center;

  .picture {
    margin-right: 20px;
    width: auto;
    height: 100%;
    border-radius: 4px;

    img {
      height: 100%;
      width: auto;
    }
  }

  .resume-title {
    height: 100%;
    font-size: 24px;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
  }
}

.el-main {
  border-top: 1px solid rgba(229, 229, 229, 1);
  border-bottom: 1px solid rgba(229, 229, 229, 1);

  .content {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 38px;
  }


}

/deep/ .el-tabs__item {
  font-size: 21px !important;
  height: 53px;
}

.el-footer {
  height: auto;

  .el-row {
    display: flex;
    flex-wrap: wrap;
  }

  .picture {
    margin-right: 20px;
    width: 70px;
    height: 70px;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

</style>
