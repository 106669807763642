<!-- 面试辅导/简历辅导 的卡片 -->
<template>
  <div class="card-tpl" @click="resumeDetail">
    <div class="main-item">
      <img class="img" :src="resume.avatar" />
      <div class="item-right">
        <div class="title">{{ resume.title }}</div>
        <div class="resume-tags">
        <span v-for="(tag,index) in getResumeTags()" class="tag" :key="index">
          {{ tag }}
        </span>
        </div>
        <p class="text">
          {{ resume.reason }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ResumeItem",
  props: {
    resume: {
      type: Object,
      default: null
    },
    albumPrice: {
      type: Number
    },
    openResume: {
      type: Function,
      default: null
    }
  },
  data() {
    return {};
  },
  mounted() {
  },
  computed: {
    canSeeDetail() {
      if (this.isFree) {
        return true;
      } else if (this.userInfo?.isVip === 1) {
        return true;
      }
      return false;
    },
    ...mapGetters(["userInfo"])

  },
  methods: {
    resumeDetail() {
      console.log("this.$parent", this.$parent);
      if (this.albumPrice > 0) {
        //收费
        if (this.userInfo?.isVip === 1) {
          if(this.openResume){
            this.openResume(this.resume.id);
          }
        } else {
          this.UPDATE_VIP_VISIBLE(true);
        }
      } else {
        //免费
        this.$parent.openResume(this.resume.id);
      }
    },
    getResumeTags() {
      let tags = [];
      if (this.resume) {
        if (this.resume.jobTags) {
          tags = tags.concat(this.resume.jobTags);
        }
        if (this.resume.businessTags) {
          tags = tags.concat(this.resume.businessTags);
        }
      }
      return tags;
    },
    ...mapActions(["UPDATE_LOGIN_VISIABLE", "UPDATE_VIP_VISIBLE"])

  }
};
</script>
<style lang="scss" scoped>
.card-tpl {
  width: 100%;
  margin-bottom: 20px;

  .main-item {
    display: flex;

    .img {
      width: 133px;
      height: 187px;
      margin-right: 27px;
      border-radius: 10px;
      object-fit: cover;
    }

    .item-right {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 21px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 29px;
      }

      .resume-tags {
        margin-top: 13px;
        display: flex;
        margin-bottom: 13px;
        flex-direction: row;
        overflow-x: auto;

        .tag {
          white-space: nowrap;
          flex-direction: column;
          align-items: center;
          padding: 4px 12px;
          background: rgba(0, 82, 254, 0.1);
          border-radius: 4px;
          font-size: 16px;
          text-align: center;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0052FE;
          line-height: 23px;
          margin-right: 12px;
        }
      }

      .text {
        font-size: 19px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        white-space: pre-line;
      }
    }
  }
}
</style>
